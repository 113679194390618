/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.screen-reader-text:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/* Initially missed the font link about typekit. Used Google fonts first but will leave here since it's been added. 
   To use remaining fonts that isn't available from Google (e.g. licensed fonts);
*/
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500&display=swap");

:root {
  --color-orange: #c6814a;
  --color-button-orange: #f39c01;
  --color-red: #dd4d3a;
  --color-gray: 48, 48, 48;
  --color-stone: #f5f5ef;
  --color-teal: #306f66;
  --color-salmon-dark: #be592c;
}

body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-family: "Work Sans";
}

.App {
  max-width: 600px;
  margin: 0 auto;
}

/* 
Header
*/
.header {
  display: flex;
  justify-content: space-between;
  padding: 20px 32px;
  border-bottom: 1px solid #30303080;
}

.header__logo {
  text-transform: uppercase;
}

.header__logo img {
  margin-left: -30px;
}

.header__cart {
  display: inline-block;
}

.header__cart-button {
  position: relative;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.header__cart-item-count {
  position: absolute;
  background-color: var(--color-red);
  border-radius: 50%;
  top: -2px;
  right: -2px;
  padding: 2px 6px;
  color: #fff;
  font-size: 10px;
}

/* 
Product/Home page
*/
.product-listing {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  grid-column-gap: 10px;
  grid-row-gap: 14px;
  padding: 40px 20px;
}

.product {
  display: flex;
  flex-flow: column nowrap;
}

.product__image-wrapper {
  width: 100%;
  margin-bottom: 9px;
}

.product__image-bg {
  width: 100%;
  padding-top: 100%;
  margin: 0;
  height: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.product__image {
  display: inline-block;
  width: 100%;
  height: auto;
}

.product__brand {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.4;
  text-transform: uppercase;
  margin: 0 0 4px;
}

.product__title {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  margin: 0 0 8px;
}

.product__bottom {
  margin-top: auto;
}

.product__retail-value {
  margin-left: 15px;
  text-decoration: line-through;
  color: var(--color-orange);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
}

.product__discount {
  color: var(--color-orange);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 14px;
}

.product button {
  cursor: pointer;
  font-family: "gill-sans-nova";
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  color: #fff;
  background-color: var(--color-button-orange);
  box-shadow: none;
  border: none;
  padding: 9px 20px;
  width: 100%;
}

.product button:hover {
  filter: brightness(1.2);
}

/* 
Cart page
*/
.cart__summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  line-height: 1.4;
  background-color: var(--color-stone);
}

.cart__summary-total {
  font-size: 18px;
  font-family: "Work Sans";
  font-weight: 600;
  line-height: 1.4;
}

.cart__summary-savings {
  font-size: 15px;
  font-family: "Work Sans";
  font-weight: 400;
  line-height: 1.4;
  color: rgb(var(--color-gray));
}

.cart__list {
  padding-left: 8px;
  padding-right: 8px;
}

.cart__list-line-item {
  padding: 20px 0 11px;
  border-bottom: 1px solid rgba(var(--color-gray), 0.2);
}

.cart-product {
  display: flex;
}

.cart-product--left {
  flex: 1 30%;
  text-align: center;
}

.cart-product__discount {
  display: flex;
  justify-content: center;
  font-size: 15px;
  font-family: "Work Sans";
  font-weight: 600;
  line-height: 1.4;
  color: var(--color-teal);
  text-transform: uppercase;
}

.cart-product--right {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  flex: 1 50%;
}

.cart-product__image {
  display: inline-block;
  height: 96px;
  max-height: 96px;
  width: auto;
}

.cart-product__inputs {
  margin-bottom: 10px;
}

.cart-product__brand,
.cart-product__inputs label {
  font-size: 15px;
  font-family: "Work Sans";
  font-weight: 400;
  line-height: 1.4;
  color: rgba(var(--color-gray), 0.68);
  margin: 0 0 2px;
}

.cart-product__inputs select {
  margin-right: 8px;
}

.cart-product__title {
  font-size: 15px;
  font-family: "Work Sans";
  font-weight: 400;
  line-height: 1.4;
  margin: 0 0 10px;
  color: rgb(var(--color-gray));
}

.cart-product__remove-btn {
  background: transparent;
  border: none;
  text-decoration: underline;
  cursor: pointer;
}

.cart-product__prices {
  display: flex;
  margin-top: auto;
}

.cart-product__current-price {
  color: var(--color-salmon-dark);
  font-size: 15px;
  font-family: "Work Sans";
  font-weight: 600;
  line-height: 1.4;
  margin-right: 8px;
}

.cart-product__retail-value {
  text-decoration: line-through;
  color: rgba(var(--color-gray), 0.68);
  margin-right: 8px;
}

.cart-product__saved {
  margin-left: auto;
  color: rgb(var(--color-gray));
  filter: brightness(1.68);
}
