@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500&display=swap);
@import url(https://p.typekit.net/p.css?s=1&k=ixl0xeg&ht=tk&f=6768.6769.6770.6771.6772.6773.9869.9870.9871.9872.9873.9874.9875.14600.14602.14603.37540.37541.37542.37543.37544.37545.37546.39295.39296.39297.39298.39302.39303.39304.39305.39306.39307.44866.44867.44868.44869.44870.44871.44872.44873.44874.44875.44876.44877&a=912614&app=typekit&e=css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.screen-reader-text:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/* Initially missed the font link about typekit. Used Google fonts first but will leave here since it's been added. 
   To use remaining fonts that isn't available from Google (e.g. licensed fonts);
*/

:root {
  --color-orange: #c6814a;
  --color-button-orange: #f39c01;
  --color-red: #dd4d3a;
  --color-gray: 48, 48, 48;
  --color-stone: #f5f5ef;
  --color-teal: #306f66;
  --color-salmon-dark: #be592c;
}

body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-family: "Work Sans";
}

.App {
  max-width: 600px;
  margin: 0 auto;
}

/* 
Header
*/
.header {
  display: flex;
  justify-content: space-between;
  padding: 20px 32px;
  border-bottom: 1px solid #30303080;
}

.header__logo {
  text-transform: uppercase;
}

.header__logo img {
  margin-left: -30px;
}

.header__cart {
  display: inline-block;
}

.header__cart-button {
  position: relative;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.header__cart-item-count {
  position: absolute;
  background-color: #dd4d3a;
  background-color: var(--color-red);
  border-radius: 50%;
  top: -2px;
  right: -2px;
  padding: 2px 6px;
  color: #fff;
  font-size: 10px;
}

/* 
Product/Home page
*/
.product-listing {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  grid-column-gap: 10px;
  grid-row-gap: 14px;
  padding: 40px 20px;
}

.product {
  display: flex;
  flex-flow: column nowrap;
}

.product__image-wrapper {
  width: 100%;
  margin-bottom: 9px;
}

.product__image-bg {
  width: 100%;
  padding-top: 100%;
  margin: 0;
  height: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.product__image {
  display: inline-block;
  width: 100%;
  height: auto;
}

.product__brand {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.4;
  text-transform: uppercase;
  margin: 0 0 4px;
}

.product__title {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  margin: 0 0 8px;
}

.product__bottom {
  margin-top: auto;
}

.product__retail-value {
  margin-left: 15px;
  text-decoration: line-through;
  color: #c6814a;
  color: var(--color-orange);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
}

.product__discount {
  color: #c6814a;
  color: var(--color-orange);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 14px;
}

.product button {
  cursor: pointer;
  font-family: "gill-sans-nova";
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  color: #fff;
  background-color: #f39c01;
  background-color: var(--color-button-orange);
  box-shadow: none;
  border: none;
  padding: 9px 20px;
  width: 100%;
}

.product button:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

/* 
Cart page
*/
.cart__summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  line-height: 1.4;
  background-color: #f5f5ef;
  background-color: var(--color-stone);
}

.cart__summary-total {
  font-size: 18px;
  font-family: "Work Sans";
  font-weight: 600;
  line-height: 1.4;
}

.cart__summary-savings {
  font-size: 15px;
  font-family: "Work Sans";
  font-weight: 400;
  line-height: 1.4;
  color: rgb(48, 48, 48);
  color: rgb(var(--color-gray));
}

.cart__list {
  padding-left: 8px;
  padding-right: 8px;
}

.cart__list-line-item {
  padding: 20px 0 11px;
  border-bottom: 1px solid rgba(48, 48, 48, 0.2);
  border-bottom: 1px solid rgba(var(--color-gray), 0.2);
}

.cart-product {
  display: flex;
}

.cart-product--left {
  flex: 1 1 30%;
  text-align: center;
}

.cart-product__discount {
  display: flex;
  justify-content: center;
  font-size: 15px;
  font-family: "Work Sans";
  font-weight: 600;
  line-height: 1.4;
  color: #306f66;
  color: var(--color-teal);
  text-transform: uppercase;
}

.cart-product--right {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  flex: 1 1 50%;
}

.cart-product__image {
  display: inline-block;
  height: 96px;
  max-height: 96px;
  width: auto;
}

.cart-product__inputs {
  margin-bottom: 10px;
}

.cart-product__brand,
.cart-product__inputs label {
  font-size: 15px;
  font-family: "Work Sans";
  font-weight: 400;
  line-height: 1.4;
  color: rgba(48, 48, 48, 0.68);
  color: rgba(var(--color-gray), 0.68);
  margin: 0 0 2px;
}

.cart-product__inputs select {
  margin-right: 8px;
}

.cart-product__title {
  font-size: 15px;
  font-family: "Work Sans";
  font-weight: 400;
  line-height: 1.4;
  margin: 0 0 10px;
  color: rgb(48, 48, 48);
  color: rgb(var(--color-gray));
}

.cart-product__remove-btn {
  background: transparent;
  border: none;
  text-decoration: underline;
  cursor: pointer;
}

.cart-product__prices {
  display: flex;
  margin-top: auto;
}

.cart-product__current-price {
  color: #be592c;
  color: var(--color-salmon-dark);
  font-size: 15px;
  font-family: "Work Sans";
  font-weight: 600;
  line-height: 1.4;
  margin-right: 8px;
}

.cart-product__retail-value {
  text-decoration: line-through;
  color: rgba(48, 48, 48, 0.68);
  color: rgba(var(--color-gray), 0.68);
  margin-right: 8px;
}

.cart-product__saved {
  margin-left: auto;
  color: rgb(48, 48, 48);
  color: rgb(var(--color-gray));
  -webkit-filter: brightness(1.68);
          filter: brightness(1.68);
}

/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * adobe-garamond-pro:
 *   - http://typekit.com/eulas/00000000000000003b9b00c5
 *   - http://typekit.com/eulas/00000000000000003b9b00c6
 *   - http://typekit.com/eulas/00000000000000003b9b00c2
 *   - http://typekit.com/eulas/00000000000000003b9b00c1
 *   - http://typekit.com/eulas/00000000000000003b9b00c3
 *   - http://typekit.com/eulas/00000000000000003b9b00c4
 * gill-sans-nova:
 *   - http://typekit.com/eulas/00000000000000007735bb73
 *   - http://typekit.com/eulas/00000000000000007735bb8d
 *   - http://typekit.com/eulas/00000000000000007735bb95
 *   - http://typekit.com/eulas/00000000000000007735bb9d
 *   - http://typekit.com/eulas/00000000000000007735bbb0
 *   - http://typekit.com/eulas/00000000000000007735bbb4
 *   - http://typekit.com/eulas/00000000000000007735bbb7
 *   - http://typekit.com/eulas/00000000000000007735bbba
 *   - http://typekit.com/eulas/00000000000000007735bbbf
 *   - http://typekit.com/eulas/00000000000000007735bbc2
 * kepler-std:
 *   - http://typekit.com/eulas/000000000000000000013146
 *   - http://typekit.com/eulas/000000000000000000013141
 *   - http://typekit.com/eulas/00000000000000000001313f
 *   - http://typekit.com/eulas/000000000000000000013140
 *   - http://typekit.com/eulas/000000000000000000013142
 *   - http://typekit.com/eulas/000000000000000000013144
 *   - http://typekit.com/eulas/000000000000000000013147
 *   - http://typekit.com/eulas/000000000000000000013143
 *   - http://typekit.com/eulas/000000000000000000013145
 *   - http://typekit.com/eulas/000000000000000000013148
 * sweet-sans-pro:
 *   - http://typekit.com/eulas/00000000000000007735c60d
 *   - http://typekit.com/eulas/00000000000000007735c611
 *   - http://typekit.com/eulas/00000000000000007735c623
 *   - http://typekit.com/eulas/00000000000000007735c61b
 *   - http://typekit.com/eulas/00000000000000007735c620
 *   - http://typekit.com/eulas/00000000000000007735c624
 *   - http://typekit.com/eulas/00000000000000007735c628
 *   - http://typekit.com/eulas/00000000000000007735c62b
 *   - http://typekit.com/eulas/00000000000000007735c62d
 *   - http://typekit.com/eulas/00000000000000007735c633
 *   - http://typekit.com/eulas/00000000000000007735c62f
 *   - http://typekit.com/eulas/00000000000000007735c631
 * work-sans:
 *   - http://typekit.com/eulas/00000000000000007735c12d
 *   - http://typekit.com/eulas/00000000000000007735c134
 *   - http://typekit.com/eulas/00000000000000007735c139
 *   - http://typekit.com/eulas/00000000000000007735c13d
 *   - http://typekit.com/eulas/00000000000000007735c14a
 *   - http://typekit.com/eulas/00000000000000007735c159
 *   - http://typekit.com/eulas/00000000000000007735c15a
 *
 * © 2009-2021 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2021-01-22 21:35:56 UTC"}*/

@font-face {
  font-family: "adobe-garamond-pro";
  src: url("https://use.typekit.net/af/af619f/00000000000000003b9b00c5/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/af619f/00000000000000003b9b00c5/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/af619f/00000000000000003b9b00c5/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "adobe-garamond-pro";
  src: url("https://use.typekit.net/af/6c275f/00000000000000003b9b00c6/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/6c275f/00000000000000003b9b00c6/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/6c275f/00000000000000003b9b00c6/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "adobe-garamond-pro";
  src: url("https://use.typekit.net/af/5cace6/00000000000000003b9b00c2/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/5cace6/00000000000000003b9b00c2/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/5cace6/00000000000000003b9b00c2/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "adobe-garamond-pro";
  src: url("https://use.typekit.net/af/2011b6/00000000000000003b9b00c1/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/2011b6/00000000000000003b9b00c1/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/2011b6/00000000000000003b9b00c1/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "adobe-garamond-pro";
  src: url("https://use.typekit.net/af/fb3638/00000000000000003b9b00c3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/fb3638/00000000000000003b9b00c3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/fb3638/00000000000000003b9b00c3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "adobe-garamond-pro";
  src: url("https://use.typekit.net/af/d68363/00000000000000003b9b00c4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/d68363/00000000000000003b9b00c4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/d68363/00000000000000003b9b00c4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: "kepler-std";
  src: url("https://use.typekit.net/af/de0ac1/000000000000000000013146/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/de0ac1/000000000000000000013146/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/de0ac1/000000000000000000013146/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "kepler-std";
  src: url("https://use.typekit.net/af/d0cd82/000000000000000000013141/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/d0cd82/000000000000000000013141/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/d0cd82/000000000000000000013141/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "kepler-std";
  src: url("https://use.typekit.net/af/3f55d3/00000000000000000001313f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/3f55d3/00000000000000000001313f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/3f55d3/00000000000000000001313f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "kepler-std";
  src: url("https://use.typekit.net/af/2c86cd/000000000000000000013140/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/2c86cd/000000000000000000013140/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/2c86cd/000000000000000000013140/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "kepler-std";
  src: url("https://use.typekit.net/af/b795d0/000000000000000000013142/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/b795d0/000000000000000000013142/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/b795d0/000000000000000000013142/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "kepler-std";
  src: url("https://use.typekit.net/af/4337b5/000000000000000000013144/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/4337b5/000000000000000000013144/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/4337b5/000000000000000000013144/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "kepler-std";
  src: url("https://use.typekit.net/af/85aad1/000000000000000000013147/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/85aad1/000000000000000000013147/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/85aad1/000000000000000000013147/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "kepler-std";
  src: url("https://use.typekit.net/af/304385/000000000000000000013143/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/304385/000000000000000000013143/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/304385/000000000000000000013143/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "kepler-std";
  src: url("https://use.typekit.net/af/411f5c/000000000000000000013145/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/411f5c/000000000000000000013145/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/411f5c/000000000000000000013145/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: "kepler-std";
  src: url("https://use.typekit.net/af/fc93d8/000000000000000000013148/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/fc93d8/000000000000000000013148/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/fc93d8/000000000000000000013148/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: "work-sans";
  src: url("https://use.typekit.net/af/9a8035/00000000000000007735c12d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/9a8035/00000000000000007735c12d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3")
      format("woff"),
    url("https://use.typekit.net/af/9a8035/00000000000000007735c12d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "work-sans";
  src: url("https://use.typekit.net/af/4ae1c4/00000000000000007735c134/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/4ae1c4/00000000000000007735c134/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3")
      format("woff"),
    url("https://use.typekit.net/af/4ae1c4/00000000000000007735c134/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: "work-sans";
  src: url("https://use.typekit.net/af/af1dcf/00000000000000007735c139/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/af1dcf/00000000000000007735c139/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/af1dcf/00000000000000007735c139/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "work-sans";
  src: url("https://use.typekit.net/af/c3941c/00000000000000007735c13d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/c3941c/00000000000000007735c13d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/c3941c/00000000000000007735c13d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "work-sans";
  src: url("https://use.typekit.net/af/5edda4/00000000000000007735c14a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/5edda4/00000000000000007735c14a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/5edda4/00000000000000007735c14a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "work-sans";
  src: url("https://use.typekit.net/af/c0e675/00000000000000007735c159/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/c0e675/00000000000000007735c159/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/c0e675/00000000000000007735c159/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "work-sans";
  src: url("https://use.typekit.net/af/5636ba/00000000000000007735c15a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/5636ba/00000000000000007735c15a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/5636ba/00000000000000007735c15a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "gill-sans-nova";
  src: url("https://use.typekit.net/af/7f1946/00000000000000007735bb73/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/7f1946/00000000000000007735bb73/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/7f1946/00000000000000007735bb73/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "gill-sans-nova";
  src: url("https://use.typekit.net/af/aefbc0/00000000000000007735bb8d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/aefbc0/00000000000000007735bb8d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/aefbc0/00000000000000007735bb8d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "gill-sans-nova";
  src: url("https://use.typekit.net/af/21fe31/00000000000000007735bb95/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/21fe31/00000000000000007735bb95/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/21fe31/00000000000000007735bb95/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "gill-sans-nova";
  src: url("https://use.typekit.net/af/06d56d/00000000000000007735bb9d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/06d56d/00000000000000007735bb9d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/06d56d/00000000000000007735bb9d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "gill-sans-nova";
  src: url("https://use.typekit.net/af/6c1269/00000000000000007735bbb0/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/6c1269/00000000000000007735bbb0/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/6c1269/00000000000000007735bbb0/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "gill-sans-nova";
  src: url("https://use.typekit.net/af/e9b431/00000000000000007735bbb4/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/e9b431/00000000000000007735bbb4/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/e9b431/00000000000000007735bbb4/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "gill-sans-nova";
  src: url("https://use.typekit.net/af/e07ccf/00000000000000007735bbb7/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/e07ccf/00000000000000007735bbb7/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/e07ccf/00000000000000007735bbb7/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "gill-sans-nova";
  src: url("https://use.typekit.net/af/e6cb79/00000000000000007735bbba/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/e6cb79/00000000000000007735bbba/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/e6cb79/00000000000000007735bbba/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: "gill-sans-nova";
  src: url("https://use.typekit.net/af/ee6e20/00000000000000007735bbbf/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/ee6e20/00000000000000007735bbbf/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/ee6e20/00000000000000007735bbbf/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "gill-sans-nova";
  src: url("https://use.typekit.net/af/7cdd67/00000000000000007735bbc2/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/7cdd67/00000000000000007735bbc2/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/7cdd67/00000000000000007735bbc2/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: "sweet-sans-pro";
  src: url("https://use.typekit.net/af/4300e2/00000000000000007735c60d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/4300e2/00000000000000007735c60d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/4300e2/00000000000000007735c60d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "sweet-sans-pro";
  src: url("https://use.typekit.net/af/7ef980/00000000000000007735c611/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/7ef980/00000000000000007735c611/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/7ef980/00000000000000007735c611/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "sweet-sans-pro";
  src: url("https://use.typekit.net/af/5c1549/00000000000000007735c623/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/5c1549/00000000000000007735c623/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/5c1549/00000000000000007735c623/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "sweet-sans-pro";
  src: url("https://use.typekit.net/af/17a1eb/00000000000000007735c61b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/17a1eb/00000000000000007735c61b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/17a1eb/00000000000000007735c61b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "sweet-sans-pro";
  src: url("https://use.typekit.net/af/e74318/00000000000000007735c620/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/e74318/00000000000000007735c620/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/e74318/00000000000000007735c620/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "sweet-sans-pro";
  src: url("https://use.typekit.net/af/10165c/00000000000000007735c624/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/10165c/00000000000000007735c624/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/10165c/00000000000000007735c624/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: "sweet-sans-pro";
  src: url("https://use.typekit.net/af/888209/00000000000000007735c628/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/888209/00000000000000007735c628/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/888209/00000000000000007735c628/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "sweet-sans-pro";
  src: url("https://use.typekit.net/af/896985/00000000000000007735c62b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/896985/00000000000000007735c62b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/896985/00000000000000007735c62b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: "sweet-sans-pro";
  src: url("https://use.typekit.net/af/b65d74/00000000000000007735c62d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/b65d74/00000000000000007735c62d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/b65d74/00000000000000007735c62d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "sweet-sans-pro";
  src: url("https://use.typekit.net/af/c92988/00000000000000007735c633/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/c92988/00000000000000007735c633/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/c92988/00000000000000007735c633/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "sweet-sans-pro";
  src: url("https://use.typekit.net/af/59a1b6/00000000000000007735c62f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/59a1b6/00000000000000007735c62f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3")
      format("woff"),
    url("https://use.typekit.net/af/59a1b6/00000000000000007735c62f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "sweet-sans-pro";
  src: url("https://use.typekit.net/af/9825bc/00000000000000007735c631/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/9825bc/00000000000000007735c631/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3")
      format("woff"),
    url("https://use.typekit.net/af/9825bc/00000000000000007735c631/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 800;
}

.tk-adobe-garamond-pro {
  font-family: "adobe-garamond-pro", serif;
}
.tk-kepler-std {
  font-family: "kepler-std", serif;
}
.tk-work-sans {
  font-family: "work-sans", sans-serif;
}
.tk-gill-sans-nova {
  font-family: "gill-sans-nova", sans-serif;
}
.tk-sweet-sans-pro {
  font-family: "sweet-sans-pro", sans-serif;
}

